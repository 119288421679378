import styled from 'styled-components'
import theme from '../../styles/theme'
import {motion} from 'framer-motion'

export const Video = styled(motion.section)`
  overflow: hidden;
  min-height: calc(100vh);
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 50px);
  }
  .reactplayer {
    ${'' /* max-width: ${theme.contentWidth}; */}
    height: 75vh !important;
    max-height: calc(100vh - 240px);
    @media (max-width: ${theme.breakpoints.md}px) {
      height: 300px !important;
    }
  }

  .textContainer {
    max-width: ${theme.contentWidth};
    padding: ${theme.paddingAround};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.2rem;
    }
    h1 {
      text-transform: uppercase;
      margin: 0;
      @media (max-width: ${theme.breakpoints.md}px) {
        font-size: 1.3rem;
      }
    }
    .smallInfo {
      color: ${theme.colors.grey};
    }
    .right {
      .smallInfo {
        text-align: right;
      }
    }
  }

  .viewMoreContainer {
    position: fixed;
    bottom:0;
    left:0;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    position: relative;
  }
    .viewMoreTitle {
      position: absolute;
      top: -52px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      background-color: ${theme.colors.dark};
      border: 1px solid ${theme.colors.light};
      border-left: none;
      z-index: 9;
      color: ${theme.colors.light};
      cursor: pointer;
    }
    .inner {
      display: flex;
      width: 100vw;
      overflow-x: scroll;
      .category {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.dark};
        gap: 1rem;
        padding: 0.5rem;
        border-top: 1px solid ${theme.colors.light};
        border-right: 1px solid ${theme.colors.light}
        box-sizing: border-box;
        .title {
          position: sticky;
          left: 0.5rem;
          top: 0;
          margin: 0;
          width: fit-content;
          @media (max-width: ${theme.breakpoints.md}px) {
            font-size: 1.1rem;
          }
        }
        .videos {
          display: flex;
          gap: 1rem;
          height: fit-content;
          a {
            width: 200px;
            height: fit-content;
            .textContainer.small {
              h3 {
                font-size: 1rem;
                margin: 0;
                font-weight: normal !important;
              }
              .smallInfo {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
`
