import React from 'react'
import * as Styled from './Video.style'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import {motion} from 'framer-motion'

import Helmet from 'react-helmet'
import ReactPlayer from 'react-player'
import useWindow from '../../utils/hooks/useWindow'

var slugify = require('slugify')

function Video({pageContext}) {
  const {allOrderedVideos} = pageContext
  const {isMobileView} = useWindow()
  const allOrderedVideosTab = Object.entries(allOrderedVideos)
  const {url, category, title, length, year} = pageContext.currentVideo

  return (
    <Styled.Video>
      <Helmet title={title + ''} htmlAttributes={{lang: 'fr'}} />
      <ReactPlayer
        className="reactplayer"
        controls
        url={url}
        width="100vw"
        style={{objectFit: 'cover'}}
      />

      <div className="textContainer">
        <div className="left">
          <h1>{title}</h1>
          <div className="smallInfo">{category}</div>
        </div>
        <div className="right">
          <div className="smallInfo">{length}</div>
          <div className="smallInfo">{year}</div>
        </div>
      </div>

      <motion.div
        initial={{
          transform: isMobileView ? 'translateY(0px)' : 'translateY(225px)'
        }}
        animate={{
          transform: isMobileView ? 'translateY(0px)' : 'translateY(225px)'
        }}
        whileHover={{transform: 'translateY(0px)'}}
        style={{marginTop: isMobileView ? '3rem' : '-7rem'}}
        transition={{duration: 0.3}}
        className="viewMoreContainer"
      >
        <div className="viewMoreTitle">MORE VIDEOS</div>
        <div className="inner">
          {allOrderedVideosTab.map(([key, value], i) => {
            return (
              <div className="category" key={i}>
                <h2 className="title">{key}</h2>
                <div className="videos">
                  {value.map((video, index) => {
                    const image = getImage(
                      video.thumbnail.localFile.childImageSharp
                    )
                    const sluggedTitle = slugify(video.title, {
                      lower: true,
                      strict: true,
                      locale: 'fr'
                    })
                    return (
                      <Link to={`/video/${sluggedTitle}`} key={index}>
                        <GatsbyImage
                          image={image}
                          alt={video.title}
                          aspectRatio={16 / 9}
                        />
                        <div className="textContainer small">
                          <div className="left">
                            <h3>{video.title}</h3>
                            <div className="smallInfo">{video.category}</div>
                          </div>
                          <div className="right">
                            <div className="smallInfo">{video.length}</div>
                            <div className="smallInfo">{video.year}</div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </motion.div>
    </Styled.Video>
  )
}

export default Video
